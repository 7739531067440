import { ConditionMatcherFactory } from "./ConditionMatcher"
import { Properties, Target, TargetCondition, User } from "../../model/model"
import Workspace from "../../workspace/Workspace"

export default class TargetMatcher {

  private conditionMatcherFactory: ConditionMatcherFactory

  constructor(conditionMatcherFactory: ConditionMatcherFactory) {
    this.conditionMatcherFactory = conditionMatcherFactory
  }

  matches(target: Target, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    return target.conditions.every((it) => this._matches(it, workspace, user, hackleProperties))
  }

  _matches(condition: TargetCondition, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    const conditionMatcher = this.conditionMatcherFactory.getMatcher(condition.key.type)
    return conditionMatcher.matches(condition, workspace, user, hackleProperties)
  }
}
