import TargetMatcher from "../match/TargetMatcher"
import ActionResolver from "../action/ActionResolver"
import Workspace from "../../workspace/Workspace"
import { Experiment, Properties, User, Variation } from "../../model/model"

export default class OverrideResolver {

  private targetMatcher: TargetMatcher
  private actionResolver: ActionResolver

  constructor(targetMatcher: TargetMatcher, actionResolver: ActionResolver) {
    this.targetMatcher = targetMatcher
    this.actionResolver = actionResolver
  }

  resolveOrNull(workspace: Workspace, experiment: Experiment, user: User, hackleProperties: Properties): Variation | undefined {
    const overriddenVariationId = experiment.userOverrides.get(user.id)
    if (overriddenVariationId) {
      return experiment._getVariationByIdOrNull(overriddenVariationId)
    }

    const overriddenRule = experiment.segmentOverrides.find((it) => this.targetMatcher.matches(it.target, workspace, user, hackleProperties))
    if (!overriddenRule) {
      return undefined
    }

    return this.actionResolver.resolveOrNull(overriddenRule.action, workspace, experiment, user)
  }
}