export const WORKSPACE_FETCH_URL = "https://sdk.hackle.io/api/v2/workspaces"

export const EVENT_DISPATCH_URL = "https://event.hackle.io/api/v2/events"

export const SDK_KEY_HEADER = "X-HACKLE-SDK-KEY"

export const SDK_NAME_HEADER = "X-HACKLE-SDK-NAME"

export const SDK_VERSION_HEADER = "X-HACKLE-SDK-VERSION"

export const DEFAULT_POOL_INTERVAL = 10000

export const REQUEST_TIME_OUT = 10000

export const DEFAULT_BATCH_SIZE = 1000

export const DEFAULT_FLUSH_INTERVAL = 10000

export const DEFAULT_ON_READY_TIMEOUT = 3000

export const ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT = [0, 8, 16, 32, 64, 128, 256, 512]
