import EventProcessor from "../event/EventProcessor"
import {
  Decision,
  DecisionReason,
  FeatureFlagDecision,
  HackleEvent,
  Properties,
  User,
  VariationKey
} from "../model/model"
import Event from "../event/Event"
import { EventEmitter } from "events"
import { DEFAULT_ON_READY_TIMEOUT } from "../config"
import Logger from "../logger"
import WorkspaceFetcher from "../workspace/WorkspaceFetcher"
import Evaluator from "../evaluation/Evaluator"
import EvaluationFlowFactory from "../evaluation/flow/EvaluationFlowFactory"

const log = Logger.log

export default class HackleInternalClient {
  private workspaceFetcher: WorkspaceFetcher
  private eventProcessor: EventProcessor
  private eventEmitter: EventEmitter
  private evaluator: Evaluator = new Evaluator(new EvaluationFlowFactory())
  private readonly readyPromise: any

  constructor(workspaceFetcher: WorkspaceFetcher, eventProcessor: EventProcessor, eventEmitter: EventEmitter) {
    this.workspaceFetcher = workspaceFetcher
    this.eventProcessor = eventProcessor
    this.eventEmitter = eventEmitter
    this.workspaceFetcher.start()
    this.eventProcessor.start()
    this.readyPromise = this.workspaceFetcher.onReady().then(
      () => {
        return { success: true }
      },
      () => {
        return { success: false }
      }
    )
  }

  _experiment(
    experimentKey: number,
    user: User,
    hackleProperties: Properties,
    defaultVariation: VariationKey
  ): Decision {
    if (!experimentKey) {
      log.error("experimentKey must not be empty")
      return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
    }

    if (user) {
      if (!user.id || typeof user.id !== "string") {
        log.error("user.id must not be null and must be string type")
        return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
      }
    } else {
      log.error("user must not be null.")
      return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return Decision.of(defaultVariation, DecisionReason.SDK_NOT_READY)
    }

    const experiment = workspace.getExperimentOrNull(experimentKey)

    if (!experiment) {
      log.warn("Experiment does not exist.")
      return Decision.of(defaultVariation, DecisionReason.EXPERIMENT_NOT_FOUND)
    }

    const evaluation = this.evaluator.evaluate(workspace, experiment, user, hackleProperties, defaultVariation)
    this.eventProcessor.process(Event.exposure(experiment, user, evaluation, hackleProperties))

    return Decision.of(evaluation.variationKey, evaluation.reason)
  }

  _featureFlag(featureKey: number, user: User, hackleProperties: Properties): FeatureFlagDecision {
    if (!featureKey) {
      log.error("featureKey must not be empty")
      return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
    }

    if (user) {
      if (!user.id || typeof user.id !== "string") {
        log.error("user.id must not be null and must be string type")
        return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
      }
    } else {
      log.error("user must not be null.")
      return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return FeatureFlagDecision.off(DecisionReason.SDK_NOT_READY)
    }

    const featureFlag = workspace.getFeatureFlagOrNull(featureKey)

    if (!featureFlag) {
      log.warn("FeatureFlag does not exist.")
      return FeatureFlagDecision.off(DecisionReason.FEATURE_FLAG_NOT_FOUND)
    }

    const evaluation = this.evaluator.evaluate(workspace, featureFlag, user, hackleProperties, "A")
    this.eventProcessor.process(Event.exposure(featureFlag, user, evaluation, hackleProperties))

    if (evaluation.variationKey === "A") {
      return FeatureFlagDecision.off(evaluation.reason)
    } else {
      return FeatureFlagDecision.on(evaluation.reason)
    }
  }

  _track(event: HackleEvent, user: User, hackleProperties?: Properties) {
    if (!event) {
      log.warn("event must not be null.")
      return
    }

    if (typeof event !== "object") {
      log.warn("Event must be event type.")
      return
    }

    if (typeof event === "object") {
      if (!event.key || typeof event.key !== "string") {
        log.warn("Event key must be not null. or event key must be string type.")
        return
      }
    }

    if (user) {
      if (!user.id || typeof user.id !== "string") {
        log.error("Id must not be null. or Id must be string type")
        return
      }
    } else {
      log.error("User must not be null.")
      return
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready")
      return
    }

    const eventType = workspace.getEventTypeOrNull(event.key)
    this.eventProcessor.process(Event.track(eventType, event, user, hackleProperties))
  }

  _onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this._onInitialized({ timeout : timeout }).then(() => block())
  }

  _onInitialized({ timeout = DEFAULT_ON_READY_TIMEOUT }: { timeout?: number }): Promise<{ success: boolean }> {
    log.debug("Start HackleClient initializing")
    let resolveTimeoutPromise: any
    const timeoutPromise: Promise<{ success: boolean }> = new Promise((resolve) => {
      resolveTimeoutPromise = resolve
    })

    const onReadyTimeout = () => {
      resolveTimeoutPromise({
        success: false
      })
    }

    const readyTimeout = setTimeout(onReadyTimeout, timeout)

    this.readyPromise.then(() => {
      clearTimeout(readyTimeout)
      resolveTimeoutPromise({
        success: true
      })
    })

    return Promise.race([this.readyPromise, timeoutPromise]).then((result: { success: boolean }) => {
      log.debug("HackleClient onInitialized")
      return Promise.resolve({ success: result.success })
    })
  }

  _close(): void {
    this.workspaceFetcher.close()
    this.eventProcessor.close()
  }
}
