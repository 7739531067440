import Workspace from "../../workspace/Workspace"
import { DecisionReason, Experiment, Properties, User, VariationKey } from "../../model/model"
import EvaluationFlow from "./EvaluationFlow"
import { Evaluation } from "../Evaluator"
import ExperimentTargetDeterminer from "../target/ExperimentTargetDeterminer"
import ActionResolver from "../action/ActionResolver"
import TargetRuleDeterminer from "../target/TargetRuleDeterminer"
import OverrideResolver from "../target/OverrideResolver"

export default interface FlowEvaluator {
  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation
}

export class OverrideEvaluator implements FlowEvaluator {

  private overrideResolver: OverrideResolver

  constructor(overrideResolver: OverrideResolver) {
    this.overrideResolver = overrideResolver
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    const overriddenVariation = this.overrideResolver.resolveOrNull(workspace, experiment, user, hackleProperties)
    if (overriddenVariation) {
      switch (experiment.type) {
        case "AB_TEST":
          return Evaluation.withVariation(overriddenVariation, DecisionReason.OVERRIDDEN)
        case "FEATURE_FLAG":
          return Evaluation.withVariation(overriddenVariation, DecisionReason.INDIVIDUAL_TARGET_MATCH)
      }
    } else {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    }
  }
}

export class DraftEvaluator implements FlowEvaluator {
  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status === "DRAFT") {
      return Evaluation.of(experiment, defaultVariationKey, DecisionReason.EXPERIMENT_DRAFT)
    } else {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    }
  }
}

export class PausedEvaluator implements FlowEvaluator {
  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status === "PAUSED") {
      switch (experiment.type) {
        case "AB_TEST":
          return Evaluation.of(experiment, defaultVariationKey, DecisionReason.EXPERIMENT_PAUSED)
        case "FEATURE_FLAG":
          return Evaluation.of(experiment, defaultVariationKey, DecisionReason.FEATURE_FLAG_INACTIVE)
      }
    } else {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    }
  }
}

export class CompletedEvaluator implements FlowEvaluator {
  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status === "COMPLETED") {
      const winnerVariation = experiment._winnerVariationOrNull()
      if (!winnerVariation) {
        throw new Error(`winner variation [${experiment.id}]`)
      }
      return Evaluation.withVariation(winnerVariation, DecisionReason.EXPERIMENT_COMPLETED)
    } else {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    }
  }
}

export class ExperimentTargetEvaluator implements FlowEvaluator {

  private experimentTargetDeterminer: ExperimentTargetDeterminer

  constructor(experimentTargetDeterminer: ExperimentTargetDeterminer) {
    this.experimentTargetDeterminer = experimentTargetDeterminer
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.type !== "AB_TEST") {
      throw new Error(`experiment type bust be AB_TEST [${experiment.id}]`)
    }

    const isUserInExperimentTarget = this.experimentTargetDeterminer.isUserInExperimentTarget(workspace, experiment, user, hackleProperties)
    if (isUserInExperimentTarget) {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    } else {
      return Evaluation.of(experiment, defaultVariationKey, DecisionReason.NOT_IN_EXPERIMENT_TARGET)
    }
  }
}

export class TrafficAllocateEvaluator implements FlowEvaluator {
  private actionResolver: ActionResolver

  constructor(actionResolver: ActionResolver) {
    this.actionResolver = actionResolver
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${experiment.id}]`)
    }

    if (experiment.type !== "AB_TEST") {
      throw new Error(`experiment type bust be AB_TEST [${experiment.id}]`)
    }

    const variation = this.actionResolver.resolveOrNull(experiment.defaultRule, workspace, experiment, user)
    if (!variation) {
      return Evaluation.of(experiment, defaultVariationKey, DecisionReason.TRAFFIC_NOT_ALLOCATED)
    }

    if (variation.isDropped) {
      return Evaluation.of(experiment, defaultVariationKey, DecisionReason.VARIATION_DROPPED)
    }

    return Evaluation.withVariation(variation, DecisionReason.TRAFFIC_ALLOCATED)
  }
}

export class TargetRuleEvaluator implements FlowEvaluator {

  private targetRuleDeterminer: TargetRuleDeterminer
  private actionResolver: ActionResolver

  constructor(targetRuleDeterminer: TargetRuleDeterminer, actionResolver: ActionResolver) {
    this.targetRuleDeterminer = targetRuleDeterminer
    this.actionResolver = actionResolver
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${experiment.id}]`)
    }

    if (experiment.type !== "FEATURE_FLAG") {
      throw new Error(`experiment type bust be FEATURE_FLAG [${experiment.id}]`)
    }

    const targetRule = this.targetRuleDeterminer.determineTargetRuleOrNull(workspace, experiment, user, hackleProperties)

    if (!targetRule) {
      return nextFlow.evaluate(workspace, experiment, user, hackleProperties, defaultVariationKey)
    }

    const variation = this.actionResolver.resolveOrNull(targetRule.action, workspace, experiment, user)
    if (!variation) {
      throw new Error(`FeatureFlag must decide the Variation [${experiment.id}]`)
    }
    return Evaluation.withVariation(variation, DecisionReason.TARGET_RULE_MATCH)
  }
}

export class DefaultRuleEvaluator implements FlowEvaluator {

  private actionResolver: ActionResolver

  constructor(actionResolver: ActionResolver) {
    this.actionResolver = actionResolver
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties,
    defaultVariationKey: VariationKey,
    nextFlow: EvaluationFlow
  ): Evaluation {
    if (experiment.status !== "RUNNING") {
      throw new Error(`experiment status must be RUNNING [${experiment.id}]`)
    }

    if (experiment.type !== "FEATURE_FLAG") {
      throw new Error(`experiment type bust be FEATURE_FLAG [${experiment.id}]`)
    }

    const variation = this.actionResolver.resolveOrNull(experiment.defaultRule, workspace, experiment, user)
    if (!variation) {
      throw new Error(`FeatureFlag must decide the Variation [${experiment.id}]`)
    }

    return Evaluation.withVariation(variation, DecisionReason.DEFAULT_RULE)
  }
}
