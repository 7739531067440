import TargetMatcher from "../match/TargetMatcher"
import { ConditionMatcherFactory } from "../match/ConditionMatcher"
import ActionResolver from "../action/ActionResolver"
import Bucketer from "../bucket/Bucketer"
import EvaluationFlow from "./EvaluationFlow"
import {
  CompletedEvaluator,
  DefaultRuleEvaluator,
  DraftEvaluator,
  ExperimentTargetEvaluator,
  OverrideEvaluator,
  PausedEvaluator,
  TargetRuleEvaluator,
  TrafficAllocateEvaluator
} from "./FlowEvaluator"
import ExperimentTargetDeterminer from "../target/ExperimentTargetDeterminer"
import TargetRuleDeterminer from "../target/TargetRuleDeterminer"
import { ExperimentType } from "../../model/model"
import OverrideResolver from "../target/OverrideResolver"

export default class EvaluationFlowFactory {

  private readonly abTestFlow: EvaluationFlow
  private readonly featureFlagFlow: EvaluationFlow

  constructor() {

    const targetMatcher = new TargetMatcher(new ConditionMatcherFactory())
    const actionResolver = new ActionResolver(new Bucketer())
    const overrideResolver = new OverrideResolver(targetMatcher, actionResolver)

    this.abTestFlow = EvaluationFlow.of(
      new OverrideEvaluator(overrideResolver),
      new ExperimentTargetEvaluator(new ExperimentTargetDeterminer(targetMatcher)),
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new TrafficAllocateEvaluator(actionResolver)
    )

    this.featureFlagFlow = EvaluationFlow.of(
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new OverrideEvaluator(overrideResolver),
      new TargetRuleEvaluator(new TargetRuleDeterminer(targetMatcher), actionResolver),
      new DefaultRuleEvaluator(actionResolver)
    )
  }

  getFlow(experimentType: ExperimentType): EvaluationFlow {
    switch (experimentType) {
      case "AB_TEST":
        return this.abTestFlow
      case "FEATURE_FLAG":
        return this.featureFlagFlow
    }
  }
}
