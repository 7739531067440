import {
  Bucket,
  BucketId,
  EventKey,
  EventType,
  Experiment,
  ExperimentKey,
  ExperimentStatus,
  ExperimentType,
  MATCH_OPERATORS,
  MATCH_TYPES,
  MATCH_VALUE_TYPES,
  Segment,
  SEGMENT_TYPES,
  SegmentKey,
  Slot,
  Target,
  TARGET_ACTION_TYPES,
  TARGET_KEY_TYPES,
  TargetAction,
  TargetCondition,
  TargetingType,
  TargetKey,
  TargetMatch,
  TargetRule,
  Variation
} from "../model/model"
import {
  BucketDto,
  ExperimentDto,
  SegmentDto,
  TargetActionDto,
  TargetConditionDto,
  TargetDto,
  TargetKeyDto,
  TargetMatchDto,
  TargetRuleDto,
  WorkspaceDto
} from "./dto"
import Logger from "../logger"

const log = Logger.log

export default class Workspace {
  private experiments: Map<ExperimentKey, Experiment>
  private featureFlags: Map<ExperimentKey, Experiment>
  private buckets: Map<BucketId, Bucket>
  private eventTypes: Map<EventKey, EventType>
  private segments: Map<SegmentKey, Segment>

  constructor(
    experiments: Map<ExperimentKey, Experiment>,
    featureFlags: Map<ExperimentKey, Experiment>,
    buckets: Map<BucketId, Bucket>,
    eventTypes: Map<EventKey, EventType>,
    segments: Map<SegmentKey, Segment>
  ) {
    this.experiments = experiments
    this.featureFlags = featureFlags
    this.buckets = buckets
    this.eventTypes = eventTypes
    this.segments = segments
  }

  getExperimentOrNull(experimentKey: ExperimentKey): Experiment | undefined {
    return this.experiments.get(experimentKey)
  }

  getFeatureFlagOrNull(featureKey: ExperimentKey): Experiment | undefined {
    return this.featureFlags.get(featureKey)
  }

  getBucketOrNull(bucketId: BucketId): Bucket | undefined {
    return this.buckets.get(bucketId)
  }

  getEventTypeOrNull(eventKey: EventKey): EventType {
    const eventType = this.eventTypes.get(eventKey)
    if (eventType) {
      return eventType
    } else {
      return new EventType(0, eventKey)
    }
  }

  getSegmentOrNull(segmentKey: SegmentKey): Segment | undefined {
    return this.segments.get(segmentKey)
  }

  static from(dto: WorkspaceDto): Workspace {
    const buckets = Workspace.associate(dto.buckets, (it) => [it.id, this.toBucket(it)])

    const experiments: Map<ExperimentKey, Experiment> =
      Workspace.associateBy(Workspace.mapNotUndefined(dto.experiments, (it) => this.toExperimentOrNull("AB_TEST", it)), (it) => it.key)

    const featureFlags: Map<ExperimentKey, Experiment> =
      Workspace.associateBy(Workspace.mapNotUndefined(dto.featureFlags, (it) => this.toExperimentOrNull("FEATURE_FLAG", it)), (it) => it.key)

    const eventTypes =
      Workspace.associate(dto.events, (it) => [it.key, new EventType(it.id, it.key)])

    const segments: Map<SegmentKey, Segment> =
      Workspace.associateBy(Workspace.mapNotUndefined(dto.segments, (it) => this.toSegmentOrNull(it)), (it) => it.key)

    return new Workspace(experiments, featureFlags, buckets, eventTypes, segments)
  }

  private static toBucket(dto: BucketDto): Bucket {
    return new Bucket(
      dto.seed,
      dto.slotSize,
      dto.slots.map(
        ({ startInclusive, endExclusive, variationId }) => new Slot(startInclusive, endExclusive, variationId)
      )
    )
  }

  private static toExperimentOrNull(type: ExperimentType, dto: ExperimentDto): Experiment | undefined {
    const experimentStatus = this.experimentStatusOrNull(dto.execution.status)
    const variations = dto.variations.map((it) => new Variation(it.id, it.key, it.status === "DROPPED"))
    const userOverrides = Workspace.associate(dto.execution.userOverrides, (it) => [it.userId, it.variationId])
    const segmentOverrides = Workspace.mapNotUndefined(dto.execution.segmentOverrides, (it) => this.toTargetRuleOrNull(it, TargetingType.IDENTIFIER))
    const targetAudiences = Workspace.mapNotUndefined(dto.execution.targetAudiences, (it) => this.toTargetOrNull(it, TargetingType.PROPERTY))
    const targetRules = Workspace.mapNotUndefined(dto.execution.targetRules, (it) => this.toTargetRuleOrNull(it, TargetingType.PROPERTY))
    const defaultRule = this.toTargetActionOrNull(dto.execution.defaultRule)
    return experimentStatus && defaultRule && new Experiment(
      dto.id,
      dto.key,
      type,
      experimentStatus,
      variations,
      userOverrides,
      segmentOverrides,
      targetAudiences,
      targetRules,
      defaultRule,
      dto.winnerVariationId
    )
  }

  private static experimentStatusOrNull(executionStatus: string): ExperimentStatus | undefined {
    switch (executionStatus) {
      case "READY":
        return "DRAFT"
      case "RUNNING":
        return "RUNNING"
      case "PAUSED":
        return "PAUSED"
      case "STOPPED":
        return "COMPLETED"
      default:
        log.debug(`Unsupported status [${executionStatus}]`)
        return undefined
    }
  }

  private static toTargetRuleOrNull(dto: TargetRuleDto, targetingType: TargetingType): TargetRule | undefined {
    const target = this.toTargetOrNull(dto.target, targetingType)
    const action = this.toTargetActionOrNull(dto.action)
    return target && action && new TargetRule(target, action)
  }

  private static toTargetActionOrNull(dto: TargetActionDto): TargetAction | undefined {
    const type = this.parseOrNull(TARGET_ACTION_TYPES, dto.type)
    return type && new TargetAction(type, dto.variationId, dto.bucketId)
  }

  private static toTargetOrNull(dto: TargetDto, targetingType: TargetingType): Target | undefined {
    const conditions = Workspace.mapNotUndefined(dto.conditions, (it) => this.toConditionOrNull(it, targetingType))
    return new Target(conditions)
  }

  private static toConditionOrNull(dto: TargetConditionDto, targetingType: TargetingType): TargetCondition | undefined {
    const key = this.toTargetKeyOrNull(dto.key)
    if (!key) {
      return undefined
    }
    if (!targetingType.supports(key.type)) {
      return undefined
    }
    const match = this.toTargetMatchOrNull(dto.match)
    return match && new TargetCondition(key, match)
  }

  private static toTargetKeyOrNull(dto: TargetKeyDto): TargetKey | undefined {
    const keyType = this.parseOrNull(TARGET_KEY_TYPES, dto.type)
    return keyType && new TargetKey(keyType, dto.name)
  }

  private static toTargetMatchOrNull(dto: TargetMatchDto): TargetMatch | undefined {
    const matchType = this.parseOrNull(MATCH_TYPES, dto.type)
    const operator = this.parseOrNull(MATCH_OPERATORS, dto.operator)
    const valueType = this.parseOrNull(MATCH_VALUE_TYPES, dto.valueType)
    return matchType && operator && valueType && new TargetMatch(matchType, operator, valueType, dto.values)
  }

  private static toSegmentOrNull(dto: SegmentDto): Segment | undefined {
    const segmentType = this.parseOrNull(SEGMENT_TYPES, dto.type)
    return segmentType && new Segment(dto.id, dto.key, segmentType, this.mapNotUndefined(dto.targets, (it) => this.toTargetOrNull(it, TargetingType.SEGMENT)))
  }

  private static parseOrNull<T extends string>(types: readonly T[], type: string): T | undefined {
    const t = types.find((it) => it === type)
    if (!t) {
      log.debug(`Unsupported type [${type}]. Please use the latest version of sdk.`)
    }
    return t
  }

  private static mapNotUndefined<T, R>(receiver: T[], transform: (value: T) => R | undefined): Array<R> {
    return receiver.reduce((results, t) => {
      const result = transform(t)
      if (result) {
        results.push(result)
      }
      return results
    }, Array<R>())
  }

  private static associateTo<T, K, V>(receiver: T[], destination: Map<K, V>, transform: (value: T) => [K, V]): Map<K, V> {
    return receiver.reduce((map, value) => {
      const kv = transform(value)
      map.set(kv[0], kv[1])
      return map
    }, destination)
  }

  private static associate<T, K, V>(receiver: T[], transform: (value: T) => [K, V]): Map<K, V> {
    return Workspace.associateTo(receiver, new Map(), transform)
  }

  private static associateBy<T, K>(receiver: T[], keySelector: (value: T) => K): Map<K, T> {
    return this.associateTo(receiver, new Map(), (it) => [keySelector(it), it])
  }
}
