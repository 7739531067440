import { DEFAULT_BATCH_SIZE, DEFAULT_FLUSH_INTERVAL } from "../config"

export type EventQueueSink<Event> = (buffer: Event[], useBeacon?: boolean) => Promise<any>

export interface EventQueue<Event> {
  start(): void

  enqueue(event: Event): void
  close(): void
}

class Timer {
  private timeout: number
  private callback: () => void
  private timeoutId?: number

  constructor({ timeout, callback }: { timeout: number; callback: () => void }) {
    this.timeout = Math.max(timeout, 0)
    this.callback = callback
  }

  start(): void {
    this.timeoutId = setTimeout(this.callback, this.timeout) as any
  }

  refresh(): void {
    this.stop()
    this.start()
  }

  stop(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId as any)
    }
  }
}

export class DefaultEventQueue<Event> implements EventQueue<Event> {
  public timer: Timer
  private buffer: Event[]
  private maxQueueSize: number
  private sink: EventQueueSink<Event>

  private batchComparator: (eventA: Event, eventB: Event) => boolean
  private started: boolean

  constructor(
    sink: EventQueueSink<Event>,
    batchComparator: (eventA: Event, eventB: Event) => boolean,
    batchSize: number = DEFAULT_BATCH_SIZE,
    flushInterval = DEFAULT_FLUSH_INTERVAL
  ) {
    this.buffer = []
    this.sink = sink
    this.maxQueueSize = batchSize
    this.batchComparator = batchComparator
    this.timer = new Timer({
      callback: this.flush.bind(this),
      timeout: flushInterval
    })
    this.started = false
  }

  start(): void {
    this.started = true
  }

  stop(): Promise<any> {
    this.started = false
    const result = this.sink(this.buffer, true)
    this.buffer = []
    this.timer.stop()
    return result
  }

  enqueue(event: Event): void {
    if (!this.started) {
      return
    }

    if (this.buffer.length === 0) {
      this.timer.refresh()
    }

    this.buffer.push(event)

    if (this.buffer.length >= this.maxQueueSize) {
      this.flush()
    }
  }

  flush(): void {
    this.sink(this.buffer)
    this.buffer = []
    this.timer.stop()
  }

  close() {
    this.stop()
  }
}
