import EventDispatcher from "./EventDispatcher"
import Event from "./Event"
import { DefaultEventQueue, EventQueue } from "./EventQueue"
import { DEFAULT_BATCH_SIZE } from "../config"

export default class EventProcessor {
  private eventDispatcher: EventDispatcher
  private flushInterval: number = EventProcessor.DEFAULT_FLUSH_INTERVAL
  private queue: EventQueue<Event>

  static DEFAULT_FLUSH_INTERVAL: number = 10 * 1000

  constructor(
    eventDispatcher: EventDispatcher,
    batchSize: number = DEFAULT_BATCH_SIZE,
    flushInterval: number = EventProcessor.DEFAULT_FLUSH_INTERVAL
  ) {
    this.eventDispatcher = eventDispatcher
    this.flushInterval = flushInterval

    this.queue = new DefaultEventQueue(this.drainQueue.bind(this), areEventContextsEqual, batchSize, flushInterval)
  }

  drainQueue(buffer: Event[], useBeacon?: boolean): Promise<void> {
    const reqPromise = new Promise<void>((resolve) => {
      if (buffer.length === 0) {
        resolve()
        return
      }

      if (useBeacon) {
        this.eventDispatcher.dispatchXhrOrBeacon(buffer, () => {
          resolve()
        })
      } else {
        this.eventDispatcher.dispatch(buffer, () => {
          resolve()
        })
      }
    })

    return reqPromise
  }

  process(event: Event): void {
    this.queue.enqueue(event)
  }

  async start(): Promise<void> {
    this.queue.start()
  }

  stop(): Promise<any> {
    try {
      this.queue.close()
    } catch (e) {}
    return Promise.resolve()
  }

  close(): void {
    this.stop()
  }
}

export function areEventContextsEqual(eventA: Event, eventB: Event): boolean {
  return (
    eventA.timestamp == eventB.timestamp &&
    eventA.user.id == eventB.user.id &&
    eventA.user.properties == eventB.user.properties
  )
}
