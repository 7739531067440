import TargetMatcher from "../match/TargetMatcher"
import Workspace from "../../workspace/Workspace"
import { Experiment, Properties, TargetRule, User } from "../../model/model"

export default class TargetRuleDeterminer {
  private targetMatcher: TargetMatcher

  constructor(targetMatcher: TargetMatcher) {
    this.targetMatcher = targetMatcher
  }

  determineTargetRuleOrNull(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties
  ): TargetRule | undefined {
    return experiment.targetRules.find((it) => this.targetMatcher.matches(it.target, workspace, user, hackleProperties))
  }
}
