import { Properties, Segment, Target, TargetCondition, TargetKey, TargetKeyType, User } from "../../model/model"
import Workspace from "../../workspace/Workspace"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import { OperatorMatcherFactory } from "./OperatorMatcher"
import { ValueMatcherFactory } from "./ValueMatcher"

export default interface ConditionMatcher {
  matches(condition: TargetCondition, workspace: Workspace, user: User, hackleProperties: Properties): boolean
}

class UserConditionMatcher implements ConditionMatcher {

  private userValueResolver: UserValueResolver
  private valueOperatorMatcher: ValueOperatorMatcher


  constructor(userValueResolver: UserValueResolver, valueOperatorMatcher: ValueOperatorMatcher) {
    this.userValueResolver = userValueResolver
    this.valueOperatorMatcher = valueOperatorMatcher
  }

  matches(condition: TargetCondition, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    const userValue = this.userValueResolver.resolveOrNull(user, hackleProperties, condition.key)
    if (!userValue) {
      return false
    }
    return this.valueOperatorMatcher.matches(userValue, condition.match)
  }
}

class UserValueResolver {
  resolveOrNull(user: User, hackleProperties: Properties, key: TargetKey): any | undefined {
    switch (key.type) {
      case "USER_ID":
        return user.id
      case "USER_PROPERTY":
        return user.properties && user.properties[key.name]
      case "HACKLE_PROPERTY":
        return hackleProperties[key.name]
      case "SEGMENT":
        throw new Error(`Unsupported TargetKeyType [${key.type}]`)
    }
  }
}

class SegmentConditionMatcher implements ConditionMatcher {

  private segmentMatcher: SegmentMatcher

  constructor(segmentMatcher: SegmentMatcher) {
    this.segmentMatcher = segmentMatcher
  }

  matches(condition: TargetCondition, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    if (condition.key.type != "SEGMENT") {
      throw new Error(`Unsupported TargetKeyType [${condition.key.type}]`)
    }

    const isMatched = condition.match.values.some((it) => this._matches(it, workspace, user, hackleProperties))

    switch (condition.match.type) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }

  _matches(value: any, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    if (typeof value !== "string") {
      throw new Error(`SegmentKey[${value}]`)
    }
    const segment = workspace.getSegmentOrNull(value)
    if (!segment) {
      throw new Error(`Segment[${value}]`)
    }
    return this.segmentMatcher.matches(segment, workspace, user, hackleProperties)
  }
}

class SegmentMatcher {
  private userConditionMatcher: UserConditionMatcher

  constructor(userConditionMatcher: UserConditionMatcher) {
    this.userConditionMatcher = userConditionMatcher
  }

  matches(segment: Segment, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    return segment.targets.some((it) => this._matches(it, workspace, user, hackleProperties))
  }

  _matches(target: Target, workspace: Workspace, user: User, hackleProperties: Properties): boolean {
    return target.conditions.every((it) => this.userConditionMatcher.matches(it, workspace, user, hackleProperties))
  }
}

export class ConditionMatcherFactory {

  private static USER_CONDITION_MATCHER = new UserConditionMatcher(
    new UserValueResolver(),
    new ValueOperatorMatcher(new ValueMatcherFactory(), new OperatorMatcherFactory())
  )

  private static SEGMENT_CONDITION_MATCHER = new SegmentConditionMatcher(
    new SegmentMatcher(ConditionMatcherFactory.USER_CONDITION_MATCHER)
  )

  getMatcher(type: TargetKeyType): ConditionMatcher {
    switch (type) {
      case "USER_ID":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "USER_PROPERTY":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "HACKLE_PROPERTY":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "SEGMENT":
        return ConditionMatcherFactory.SEGMENT_CONDITION_MATCHER
    }
  }
}
