import {
  DecisionReason,
  EventType,
  Experiment,
  Long,
  Properties,
  User,
  HackleEvent,
  VariationId,
  VariationKey
} from "../model/model"
import { ExposureEventDto, TrackEventDto } from "./dto"
import { Evaluation } from "../evaluation/Evaluator"
import PropertyUtil from "../util/PropertyUtil"

export default class Event {
  timestamp: Long
  user: User
  hackleProperties?: Properties

  constructor(timestamp: Long, user: User, hackleProperties: Properties) {
    this.timestamp = timestamp
    this.user = user
    this.hackleProperties = hackleProperties
  }

  static exposure(experiment: Experiment, user: User, evaluation: Evaluation, hackleProperties: Properties = {}) {
    return new Exposure(
      new Date().getTime(),
      user,
      experiment,
      evaluation.variationId,
      evaluation.variationKey,
      evaluation.reason,
      hackleProperties
    )
  }

  static track(eventType: EventType, event: HackleEvent, user: User, hackleProperties: Properties = {}) {
    return new Track(new Date().getTime(), user, eventType, event, hackleProperties)
  }

  static isExposure(event: Event): event is Exposure {
    return (event as Exposure).experiment !== undefined
  }

  static isTrack(event: Event): event is Track {
    return (event as Track).eventType !== undefined
  }
}

export class Exposure extends Event {
  timestamp: Long
  user: User
  hackleProperties?: Properties
  experiment: Experiment
  variationId?: VariationId
  variationKey: VariationKey
  decisionReason: DecisionReason

  constructor(
    timestamp: Long,
    user: User,
    experiment: Experiment,
    variationId: VariationId | undefined,
    variationKey: VariationKey,
    decisionReason: DecisionReason,
    hackleProperties: Properties
  ) {
    super(timestamp, user, hackleProperties)
    this.experiment = experiment
    this.variationId = variationId
    this.variationKey = variationKey
    this.decisionReason = decisionReason
  }

  toDto(): ExposureEventDto {
    return {
      timestamp: this.timestamp,
      userId: this.user.id,
      userProperties: PropertyUtil.filteredProperties(this.user.properties || {}),
      experimentId: this.experiment.id,
      experimentKey: this.experiment.key,
      experimentType: this.experiment.type,
      variationId: this.variationId,
      variationKey: this.variationKey,
      decisionReason: this.decisionReason.toString(),
      hackleProperties: PropertyUtil.filteredProperties(this.hackleProperties || {}),
    }
  }
}

export class Track extends Event {
  eventType: EventType
  event: HackleEvent
  hackleProperties?: Properties

  constructor(timestamp: Long, user: User, eventType: EventType, event: HackleEvent, hackleProperties: Properties) {
    super(timestamp, user, hackleProperties)
    this.eventType = eventType
    this.event = event
  }

  toDto(): TrackEventDto {
    return {
      timestamp: this.timestamp,
      userId: this.user.id,
      userProperties: PropertyUtil.filteredProperties(this.user.properties || {}),
      eventTypeId: this.eventType.id,
      eventTypeKey: this.eventType.key,
      value: this.event.value || 0,
      properties: PropertyUtil.filteredProperties(this.event.properties || {}),
      hackleProperties: PropertyUtil.filteredProperties(this.hackleProperties || {}),
    }
  }
}
