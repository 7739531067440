import TargetMatcher from "../match/TargetMatcher"
import Workspace from "../../workspace/Workspace"
import { Experiment, Properties, User } from "../../model/model"

export default class ExperimentTargetDeterminer {

  private targetMatcher: TargetMatcher

  constructor(targetMatcher: TargetMatcher) {
    this.targetMatcher = targetMatcher
  }

  isUserInExperimentTarget(
    workspace: Workspace,
    experiment: Experiment,
    user: User,
    hackleProperties: Properties
  ): boolean {
    if (experiment.targetAudiences.length === 0) {
      return true
    }

    return experiment.targetAudiences.some((it) => this.targetMatcher.matches(it, workspace, user, hackleProperties))
  }
}
