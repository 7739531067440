import { Double, Long, Properties } from "../model/model"
import Event from "./Event"

export interface EventPayloadDto {
  exposureEvents: ExposureEventDto[]
  trackEvents: TrackEventDto[]
}

export interface ExposureEventDto {
  timestamp: Long
  userId: string
  userProperties: Properties
  experimentId: Long
  experimentKey: Long
  experimentType: string
  variationId?: Long
  variationKey: string
  decisionReason: string
  hackleProperties?: Properties
}

export interface TrackEventDto {
  timestamp: Long
  userId: string
  userProperties: Properties
  eventTypeId: Long
  eventTypeKey: string
  value: Double
  hackleProperties?: Properties
  properties: Properties
}

export function toPayload(events: Event[]): EventPayloadDto {
  const exposures = new Array<ExposureEventDto>()
  const tracks = new Array<TrackEventDto>()

  events.forEach((event) => {
    if (Event.isExposure(event)) {
      exposures.push(event.toDto())
    } else if (Event.isTrack(event)) {
      tracks.push(event.toDto())
    }
  })

  return {
    exposureEvents: exposures,
    trackEvents: tracks
  }
}
